/*/ Global /*/

a,
a:hover {
    text-decoration: none;
    color: grey;
}

.pointer {
    cursor: pointer;
}

button,
input,
label,
textarea {
    box-shadow: none !important;
}

select {
    background-color: #fff;
    text-align: center;
    text-align-last: center;
    padding: 0.25rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}

.text-y-shadow {
    text-shadow: 1px 1px #bd8d00;
}

.fixed-top,
.z-1000 {
    z-index: 1000;
}

hr {
    margin: auto;
    border-top: 3px dotted black;
    color: transparent;
    width: 40%;
}


/* Align */

.align-center {
    align-items: center
}

.align-fe {
    align-self: flex-end;
}


/* List */

.list-title {
    font-size: 18px;
}

.no-li-style {
    /* list-style: none; */
    /* width: 10vw; */
    text-align: center;
    margin: auto;
    padding: 1rem 0;
}

.no-li-bullets {
    list-style: none;
}


/* Background */

.bg-light-o {
    background-color: rgba(255, 255, 255, 0.65);
}

.bg-warning-o {
    background-color: rgba(255, 231, 14, 0.35);
}


/*/ Forms /*/

.form-check-input[type="checkbox"] {
    border: 1px solid #ffc107;
}

.form-check-input[type="checkbox"].checkbox-bg:checked {
    background-color: #ffc107!important;
}


/* Input */

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    background-color: transparent;
}

input:read-only,
textarea:read-only {
    background-color: transparent !important;
    border: none;
}

input:read-only.main {
    background-color: transparent !important;
    border: none;
    font-size: 30px;
    font-weight: bold;
}

input:read-only.secondary,
textarea:read-only {
    background-color: transparent !important;
    border: none;
    font-size: 16px;
}

.invalid input {
    border-color: red;
    border: 2px solid red;
}

input,
textarea {
    border-radius: 1.5rem !important;
}


/* Images */

.img-container {
    width: min(50vh, 80vw);
    height: min(50vh, 80vw);
    min-width: 200px;
    min-height: 200px;
    float: left;
}

.img-offer-h {
    height: min(60vh, 100vw - 16px);
}

.img-init {
    height: 250px;
    width: 666px;
}

.img-preview {
    width: 75px;
    height: 75px;
    float: left;
    margin-left: 10px;
    position: absolute;
    left: 0;
    bottom: 20%;
}


/* Grid */

.grid-overflow {
    height: 70vh;
    overflow-y: auto;
}

.grid-overflow::-webkit-scrollbar {
    width: 0.25vw;
    /* hide Scrollbar  */
    display: flex;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}

.grid .card,
.x-slider .card {
    transition: all 200ms ease-in-out;
}

.grid .card:hover,
.x-slider .card:hover {
    transform: scale(1.05);
}


/* Overflow */

.of-hidden {
    overflow: hidden;
}

.of-wrap {
    overflow-wrap: break-word;
}


/* Display */

.show-opt {
    display: block !important;
}

.show-none {
    display: none;
}


/* Sizes */

.screen-view {
    min-height: 90vh;
}

.w100 {
    width: 100px;
}

.w140 {
    width: 140px;
}

.w-30 {
    width: 30%;
}

.w-70 {
    width: 70%;
}

.w-70-lg {
    width: 70%;
}

.w-70-st {
    width: 70%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100-vw {
    width: 100vw;
}

.mnw-20-vw-resp {
    min-width: calc(20vw + 10vh);
}

.mw-50-vh {
    max-width: 50vh;
}

.mw-60-vh {
    max-width: 60vh;
}

.mw-70-vw {
    max-width: 70vw;
}

.mw-200-px {
    max-width: 200px;
}

.mw-300-px {
    max-width: 300px;
}

.h-15-vh {
    height: 15vh;
}

.h-75-vh {
    height: 75vh;
}

.h-80-vh {
    height: 80vh;
}

.h-100-vh {
    height: 100vh;
}

.h-64px {
    height: calc(2rem + 1.25vw + 1.25vh);
}

.mw-90-vw {
    max-width: 90vw;
}

.mw-95-vw {
    max-width: 95vw;
}

.mh-20-vh {
    max-height: 20vh;
}

.mh-30-vh {
    max-height: 30vh;
}

.mh-40-vh {
    max-height: 40vh;
}

.mh-50-vh {
    max-height: 50vh;
}

.mh-60-vh {
    max-height: 60vh;
}

.mh-80-vh {
    max-height: 80vh;
}

.mh-95-vh {
    max-height: 95vh;
}

.mh-35-vh-resp {
    max-height: calc(35vh + 5vw);
}

.ms-card {
    min-width: calc(17.5vw - 24px);
    min-height: calc(17.5vw - 32px);
}

.top-025 {
    top: 0.25rem;
}


/* Opacity */

.o-5 {
    opacity: 0.5;
}

.o-075 {
    opacity: 0.75;
}

.o-10 {
    opacity: 1;
}


/* Fonts */

.f-s {
    font-size: small;
}

.f-xs {
    font-size: x-small;
}

.f-xxs {
    font-size: xx-small;
}

.f-xl {
    font-size: calc(1.1rem + 1.15vw);
}

.f-xxl {
    font-size: calc(1.375rem + 1.5vw);
}

.fa-1-5x {
    font-size: 1.5rem;
}


/* Border */

.right-line {
    border-right: 1px solid;
}

.be-1 {
    border-right: 1px solid rgb(0 0 0 / 0.75);
}


/*/ Pages /*/


/* Init */

.init-bg {
    min-height: 100vh;
    min-width: 100vw;
}


/* Login */

.login {
    width: 50%;
}


/* Offerer */

.offerer-img {
    width: 10vw;
    height: 10vw;
    max-width: 150px;
    max-height: 150px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 50%;
}

.offerer-description {
    overflow-wrap: anywhere;
    width: 100%;
}


/* Offer */

.desc-txt {
    height: calc(100% - 215px);
    max-height: min(500px, calc(100vh - 100px));
    padding: 0 0.5rem;
    overflow: auto;
}

.desc-txt.edit {
    height: calc(100% - 215px);
    min-height: 250px;
    max-height: min(500px, calc(100vh - 100px));
    padding: 0 0.5rem;
    overflow: auto;
}


/*/ Elements /*/


/* Navbar */

.navbar-h {
    max-height: 75px;
}

.navbar-logo {
    max-width: 150px;
    max-height: 50px;
    height: 100%;
}

.small-resolution {
    display: none;
}

.navbar-search {
    height: 35px;
    margin: auto
}

.navbar-search-btn {
    display: flex;
    align-items: center;
}

.navbar-header-m {
    height: 60px;
}

.navbar-footer-m {
    margin-top: 50px
}

.navbar-circle-btn {
    max-height: 70px;
    max-width: 70px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    padding: 2px;
}

.navbar-img {
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    cursor: pointer;
}

.navbar-collapse {
    max-height: calc(100vh - 60px);
}


/* Options Menu */

.opt-pos {
    right: 1%;
    top: 65px;
}


/* Footer */

.footer {
    position: relative;
    z-index: 999;
}


/* Buttons */

#google-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}


/* Up */

.up-btn {
    color: #ffc107;
    bottom: 10%;
    right: 5%;
    cursor: pointer;
    text-decoration: none;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 40px;
    z-index: 9999;
}

.up-btn:hover {
    color: #bd8d00 !important;
}


/* Search */

.search {
    position: fixed;
    height: 68px;
    top: 48px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 15px 6px -6px #fff;
    z-index: 999;
}

.search-fixed {
    position: fixed;
    top: 54px;
    left: 0;
    width: 100%;
    padding: 17px 25%;
    margin: auto;
    z-index: 1000;
}

.search-input {
    max-width: 70vw;
    margin: auto;
}


/* Avatar */

.img-avatar {
    max-height: 60vh;
    max-width: 60vh;
    height: auto;
    width: 100%;
}

.img-avatar.profile {
    max-height: 50vh;
    max-width: 50vh;
}

.avatar {
    border-radius: 100%;
    width: 30px;
}


/* Modal */

.modal {
    top: 2.5vh;
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    left: 0px;
    height: 100%;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
}


/* Regs */

.reg {
    width: 70%;
    max-width: min(50vw, 350px);
    min-width: 200px;
    height: calc(5vh + 5vw);
    max-height: 90px;
    min-height: max(min(calc(50px + 5vw), 90px), 60px);
}

.reg-left {
    background-image: url("assets/img/Locana-person-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.reg-text {
    font-size: min(max(calc(10px + 5vw), 28px), 50px);
}


/* Cards */

.card a,
.card a:hover {
    color: black;
}

.card-img {
    width: 100%;
    height: width;
}

.card .end--1 {
    right: -1px;
}


/* Cropper */

.crop-style .cropper-crop-box {
    width: min(45vh, 70vw) !important;
    height: min(45vh, 70vw) !important;
    min-width: 175px;
    min-height: 175px;
    transform: translateX(max(calc((min(50vh, 80vw) - min(45vh, 70vw)) / 2), 12.5px)) translateY(max(calc((min(50vh, 80vw) - min(45vh, 70vw)) / 2), 12.5px)) !important;
    box-shadow: 0 0 100px black;
}

.crop-style .cropper-drag-box {
    background-color: transparent;
}

.crop-style .cropper-view-box img {
    display: none;
    transform: translateX(min(calc((min(45vh, 70vw) - min(50vh, 80vw)) / 2), -12.5px)) translateY(min(calc((min(45vh, 70vw) - min(50vh, 80vw)) / 2), -12.5px)) !important;
}


/* Favs Star */

.fas.fa-star {
    opacity: 0;
}

.fas.fa-star:hover,
.fas.fa-star.o-10 {
    opacity: 1;
}


/* Slider */

#slider {
    cursor: grab;
}

#slider.active {
    cursor: grabbing;
    transform: scale(1.1);
}

.x-slider {
    white-space: nowrap;
    overflow-x: auto;
}

.slider-card:hover {
    transform: scale(1.05);
}

.card-label {
    border-radius: 5px 0 0 5px;
    background-color: rgba(235, 175, 0, 0.5);
}


/* Image Selector */

.img-select {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}


/* QR */

.qr {
    width: fit-content;
    margin: 0 auto;
    border-radius: 1rem;
    overflow: hidden;
}


/* Categories */

.cat-pos-middle {
    position: absolute;
    z-index: 1000;
    left: calc(1.5vw + 7.75rem);
}

.cat-pos {
    text-align: center;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
}

.cat2-pos {
    width: 20vw;
    min-width: 300px;
    max-height: 60vh;
    text-align: center;
    position: absolute;
    left: 185px;
    top: 65px;
    z-index: 1000;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
}

.cat2-ul {
    max-height: 50vh;
}

.cat-item {
    padding: 0.5rem 4rem;
}


/*/ Responsive /*/

@media (max-width: 1200px) {
    /*/ Elements /*/
    /* Cards */
    .ms-card {
        min-width: calc(23.3333333vw - 24px);
        min-height: calc(23.3333333vw - 32px);
    }
}

@media (max-width: 992px) {
    /*/ Global /*/
    .lg-absolute {
        position: static !important;
    }
    .w-70 {
        width: 80%;
    }
    /*/ Pages /*/
    /* Login */
    .login {
        width: 70%;
    }
    /* Offer */
    .desc-txt {
        height: fit-content;
        min-height: 250px;
        padding: 0 0.5rem;
    }
    /*/ Elements /*/
    /* Categories */
    .cat-pos-middle {
        left: calc(1.5vw + 7.25rem);
    }
    /* Search */
    .search-fixed {
        padding: 17px 20%;
    }
    /* Favs Star */
    .fas.fa-star.o-10:hover {
        opacity: 1;
    }
    .fas.fa-star:hover {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    /*/ Pages /*/
    /* Login */
    .login {
        width: 80%;
    }
    /* Images */
    .img-init {
        height: 200px;
        width: 500px;
    }
    .small-logo {
        padding: 0.5rem 1rem 0.5rem 0;
    }
    .small-resolution {
        display: flex;
    }
    .big-resolution {
        display: none;
    }
    /*/ Elements /*/
    /* Categories */
    .cat-pos-middle {
        left: calc(1.5vw + 6.75rem);
    }
    .cat2-pos {
        left: 80px;
    }
    /* Cards */
    .ms-card {
        min-width: calc(35vw - 24px);
        min-height: calc(35vw - 32px);
    }
}

@media (max-width: 575px) {
    /*/ Global /*/
    /* Images */
    .img-init {
        height: 150px;
        width: 400px;
    }
    /* Sizes */
    .w-70 {
        width: 90%;
    }
    .w-70-lg {
        width: 95%;
    }
    .mw-100-vw {
        max-width: 100vw;
    }
    .mw-200-px.sign-in {
        max-width: 150px;
    }
    /*/ Pages /*/
    /* Login */
    .login {
        width: 90%;
    }
    /*/ Elements /*/
    /* Navbar */
    .opt-pos {
        position: static;
        text-align: center;
        border: none;
        background-color: transparent;
        padding-top: 0;
    }
    .navbar-header-m {
        height: 60px;
    }
    /* Search */
    .search {
        height: 62px;
        /* Without shadow */
        /* height: 65px; */
        box-shadow: 0 10px 6px -3px #fff;
    }
    .search-fixed {
        top: 50px;
        padding: 17px 10vw;
    }
    /* Search */
    .search-input {
        max-width: 90vw;
    }
    /* Categories */
    .cat-pos {
        position: relative;
        width: 100%;
        margin: auto;
    }
    .cat-sm-relative {
        position: relative;
    }
    .cat-pos-middle {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .cat2-pos {
        position: static;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.75);
        max-height: 40vh;
    }
    .cat2-ul {
        max-height: 30vh;
    }
    .cat2-active {
        background-color: rgba(255, 255, 255, 0.35) !important;
    }
    /* Dropdown */
    .dropdown-item {
        color: rgba(255, 255, 255, .55);
    }
    .dropdown-item.cat-item {
        color: rgba(0, 0, 0, .65);
    }
    /* Cards */
    .ms-card {
        min-width: min(calc(90vw - 24px), 50vh);
        min-height: min(calc(90vw - 32px), 50vh);
    }
}

@media (max-width: 450px) {
    /*/ Global /*/
    /* Images */
    .img-init {
        height: 125px;
        width: 340px;
    }
}

@media (max-width: 350px) {
    /*/ Global /*/
    /* Images */
    .img-init {
        height: 110px;
        width: 310px;
    }
}

@media (max-width: 320px) {
    /*/ Global /*/
    /* Images */
    .img-init {
        height: 100px;
        width: 240px;
    }
}


/*/ Scrollbar Style /*/


/* Scroll Classes */

.scroll-show::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* hide Scrollbar  */
    display: initial;
}

.scroll-show {
    /* IE and Edge */
    -ms-overflow-style: auto;
    /* Firefox */
    scrollbar-width: thin;
}


/* Width */

 ::-webkit-scrollbar {
    width: 5px;
    /* hide Scrollbar  */
    display: none;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}


/* ::-webkit-scrollbar-button {
} */


/* Track */

 ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    /* box-shadow: inset 0 0 1px grey; */
}


/* Upper Track */


/* ::-webkit-scrollbar-track-piece {
} */


/* Scroll */

 ::-webkit-scrollbar-thumb {
    background: rgba(230, 230, 230, 0.75);
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: rgba(200, 200, 200, 0.904);
}


/* Confluence */


/* ::-webkit-scrollbar-corner {} */


/* Resizer */


/* ::-webkit-resizer {} */


/*/ Animations /*/

.animated {
    transition: all 400ms ease-in-out;
}

.animated.fast {
    transition: all 200ms ease-in-out;
}

.animated-menu {
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}